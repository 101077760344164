@import "@skryv/core-ng1/theme/includes/index";
.wrp-factuurcontrole-wizard {
  margin-bottom: 2 * $base-spacing;

  .input {
    // the input class is only put on the label to avoid some distracting inheritance
    &.only-label {
      margin-bottom: 0;
    }
  }

  .factuurcontrole-empty {
    margin-bottom: $base-spacing;
  }

  .factuurcontrole-list-item-remove {
    margin-top: 0;
  }

  .factuurcontrole-wizard-table {
    width: 100%;
    margin-bottom: 0;

    td {
      border-bottom: none;
      vertical-align: top;
      padding-top: 0;

      &:not(:last-child) {
        padding-right: calc($base-spacing/2);
      }
    }

    td.goed-afkeuren {
      width: 12%;
    }
    td.bedrag {
      width: 30%;

      .currency-label {
        flex-grow: 0;
        flex-shrink: 0;
        color: $gray;
        font-weight: $font-weight-light;

        // position the label in the input field
        position: absolute;
        padding: 3px 12px;
        pointer-events: none;
      }

      input {
        text-align: right;
        padding-left: 30px;
      }
    }
    td.categorieen {
      width: 53%;
    }
    td.afwijs-reden {
      width: 53%;
    }
    td.verwijder {
      width: 5%;
    }
    td.andere-afwijs-reden {
      width: 100%;
    }

    .option-compact {
      input[type="radio"] + label {
        padding-top: 8px;
        width: 32px;
      }

      .row-subelement:not(:last-child) {
        margin-right: 3px;
      }
    }
  }

  .list-add-buttons {
    text-align: right;

    > a {
      margin-left: $base-spacing;
    }
  }

  .icon-vent {
    text-align: center;
    line-height: 16px;
    font-weight: 250;

    &::before {
      content: "G";
    }
  }
}
