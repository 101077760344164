@import "@skryv/core-ng1/theme/includes/index";
wrp-premie-berekening {
  .calculation-table {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: calc($base-spacing/2);
    align-items: flex-start;
    align-content: flex-start;

    padding: 0px $base-spacing $base-spacing $base-spacing;

    .circular-progress-inner {
      line-height: 1.5
    }
  }

  .calculation-row {
    display: flex;
    justify-content: flex-start;
    gap: calc($base-spacing/2);
    align-items: center;
    align-content: center;
  }

  .polynomial {
    display: flex;
    gap: 16px;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;

    .term {
      height: 40px;
      text-align: center;
      min-width: 40px;
      border-radius: 4px;
      padding: 8px;
      background-color: $gray-lightest;

      &.max_reached {
        background-color: $green;
        color: white;
      }

      &.max_not_reached {
        background-color: $blue;
        color: white;
      }

      span {
        padding: 0px 2px 0px 2px;
      }
    }

    .operator {
      height: 40px;
      text-align: center;
      font-size: 25px;
      font-weight: 300;
      min-width: 40px;
      border-radius: 4px;
      background-color: $gray-lightest;

      &.x {
        line-height: 40px;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .group {
      position: relative;
      border: 1px solid $gray-lightest;
      border-radius: 4px;
      padding: 8px;
      display: inherit;
      gap: 8px;

      .info {
        position: absolute;
        top: -6px;
        right: -6px;
      }
    }

    .info {
      color: $blue;
      font-size: 14px;
      font-weight: 900;
    }

    .warning {
      color: $red;
      font-size: 14px;
      font-weight: 900;
    }

    span {
      padding: 0px 2px 0px 2px;
    }
  }
}
