@import "@skryv/core-ng1/theme/includes/index";
wrp-factuurcontrole-summary-compact {
  $size-circle: 35px;

  height: 100%;

  .summary-circles {
    height: 100%;
    display: inline-flex;
    align-items: center;
  }

  .summary-circle {
    display: inline-flex;
    align-items: center;
    margin-left: calc($base-spacing/2);

    &.totaal_premie {
      padding-right: calc($base-spacing/2);
      border-right: 1px solid $secondary-menu-background;
    }

    skr-circular-progress-bar {
      .circular-progress {
        height: $size-circle;
        width: $size-circle;
      }

      .circular-progress-inner {
        line-height: $size-circle;
        font-size: $size-circle * 0.6;

        .category-icon {
          margin-bottom: 8px;
        }
      }

      .circle-icon {
        line-height: inherit;
      }
    }
  }

  .summary-circle:last-child {
    margin-right: calc($base-spacing/2);
  }
}
