// This file is implicitly imported before all stylesheets.
// This allows you to overwrite variables and mixins (and define new ones).
// They will be available in any scss file.

// IMPORTANT: This means you should not add any styling that may add rules to the eventual output.
//            In other words, this file should ONLY contain variables, mixins or placeholder selectors.
//            If you put any selectors here, they will be duplicated for every component. Nobody wants that.
//            You can also import any scss file you want, but the same idea applies to those.

@import "./wrp-icons-variables.scss";

// color pallets provided by WRP
/*
$wrp-main-dark: #15465b;
$wrp-main-middle: #23789c;
$wrp-main-light: #2b92be;

$wrp-sec1-dark: #1c7074;
$wrp-sec1-middle: #2b676d;
$wrp-sec1-light: #39b9be;

$wrp-sec2-dark: #2b5b25;
$wrp-sec2-middle: #4ba144;
$wrp-sec2-light: #5dbe55;

$wrp-sec3-dark: #843860;
$wrp-sec3-middle: #c04384;
$wrp-sec3-light: #e43891;

// used colors
$wrp-color-light: $wrp-sec1-light;
$wrp-color-dark: $wrp-main-dark;

$base-color: $wrp-color-light;
$action-color: $wrp-color-light;

$toolbar-background-color: $wrp-color-dark;
$toolbar-background-color-dark: mix(black, $wrp-color-dark, 30%);
$toolbar-background-color-light: mix(white, $wrp-color-dark, 20%);
$secondary-menu-background: $toolbar-background-color-light;

$green: $wrp-sec2-middle;
$green-light: $wrp-sec2-light;
$blue: $wrp-main-middle;
$blue-light: $wrp-main-light;

// workspace
$margin-under-header: 20px !default;
$margin-above-footer: 25px !default;
*/
$wrp-main-dark: #15465b;
$wrp-main-middle: #2b92be;
$wrp-main-light: #2b92be;

$wrp-sec1-dark: #1c7074;
$wrp-sec1-middle: #2b676d;
$wrp-sec1-light: #003399;

$wrp-sec2-dark: #2b5b25;
$wrp-sec2-middle: #4ba144;
$wrp-sec2-light: #5dbe55;

$wrp-sec3-dark: #843860;
$wrp-sec3-middle: #c04384;
$wrp-sec3-light: #e43891;

// used colors
$wrp-color-light: $wrp-sec1-light;
$wrp-color-dark: $wrp-main-dark;

$base-color: #333333;
$action-color: #0055CC;

$toolbar-background-color: $white;
$toolbar-background-color-dark: #333333;
$toolbar-background-color-light: #333333;
$secondary-menu-background: #E8EBEE;

$green: $wrp-sec2-middle;
$green-light: $wrp-sec2-light;
$blue: $action-color;
$blue-light: $wrp-main-light;

// workspace
$margin-under-header: 15px !default;
$margin-above-footer: 25px !default;
