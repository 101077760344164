@import "@skryv/core-ng1/theme/includes/index";
.wrp-category-selector .option-compact {
  input[type="checkbox"] + label {
    padding-top: 8px;
    width: 32px;
    
  }

  .row-subelement{
    width: 32px;
    height: 32px;
    margin-right: 2px;
  }

  input[disabled] + label::before {
    color: lighten($gray-lighter, 10);
  }
}


.tooltip {
  pointer-events: none;
  &.category-tooltip .tooltip-inner {
    color:black;
    background-color: white;
    border: 1px solid $gray;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
  }

  &.category-tooltip .tooltip-arrow {
    border-top-color: $gray;
  }
}

